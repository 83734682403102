import React from "react";
import Address from "./Address";
import BaseCom from "./BaseCom";
import Card from "./Card";
import FormInput from "./FormInput";
import Icon from "./Icon";
import LoadingAlert from "./LoadingAlert";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";
import L from "./Lang";

class Shipping extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            info: this.props.item ? this.props.item : {}
        };
        this.uploadRef = React.createRef();
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.fillList('/api/customer/sa/list', 'saList');
        this.fillList('/api/terr/list', 'terrList');
    }

    componentDidUpdate(prevProps) {
        if (this.props.item !== prevProps.item)
            this.setState({ info: this.props.item });
    }

    onChange(v) {
        if (this.props.onChange)
            this.props.onChange(this.props.item);
    }

    render() {
        if(!this.props.item) return;
        return <>
            <div className="row">
                <div className="col-md">
                    <Card title="Shipping" headerButtons={this.props.onRemove ? <button type="button" className="btn btn-danger" onClick={() => this.props.onRemove(this.props.item)}><Icon icon="faTrash" /></button> : null}>
                        <FormInput model={this.props.item} name="ship_num" label="Location #" type="text" labelAsPlaceholder onChange={this.onChange} disabled={this.props.disabled} preAddon="#" maxLength={10} />
                        <FormInput model={this.props.item} name="ship_name" label="Location Name" type="text" labelAsPlaceholder onChange={this.onChange} disabled={this.props.disabled} maxLength={40} />
                    </Card>
                    <Address item={this.props.item} labelAsPlaceholder onChange={this.onChange} title="Shipping Address" disabled={this.props.disabled} />
                    <Card title="Group">
                        {this.state.saList ? <FormInput model={this.props.item} name="sa_cust" label="S/A" type="select" keyName="sa_cust" options={this.state.saList} labelAsPlaceholder disabled={this.state.searchCust} /> : <LoadingAlert />}
                        {this.state.terrList ? <FormInput model={this.props.item} name="terr_code" label="Territory" type="select" keyName="terr_code" options={this.state.terrList} labelAsPlaceholder disabled={this.state.searchCust} /> : <LoadingAlert />}
                    </Card>
                </div>
                <div className="col-md">
                    <Card title="Shipping Contact">
                        <FormInput model={this.props.item} name="phone" label="Phone" type="text" labelAsPlaceholder onChange={this.onChange} disabled={this.props.disabled} maxLength={20} />
                        <FormInput model={this.props.item} name="fax" label="Fax" type="text" labelAsPlaceholder onChange={this.onChange} disabled={this.props.disabled} maxLength={20} />
                        <FormInput model={this.props.item} name="email" label="Email" type="text" labelAsPlaceholder onChange={this.onChange} disabled={this.props.disabled} maxLength={40} />
                    </Card>
                    {this.props.item.tax && <Card title="Tax">
                        <input ref={this.uploadRef} type="file" capture="camera" accept="image/*" style={{ display: 'none' }} onChange={this.upload} />
                        <FormInput model={this.props.item.tax} name="tax_references" label="Reference ID" type="text" disabled={this.props.disabled} onChange={this.onChange} maxLength={15} />
                        <FormInput model={this.props.item.tax} name="tax_expired" label="Expires" type="date" disabled={this.props.disabled} onChange={this.onChange} />
                        <button type="button" className="d-none btn btn-secondary" onClick={() => this.uploadRef.current?.click()} disabled={this.props.disabled}><Icon icon="faUpload" /> <L>Upload Tax Evidence</L></button>
                    </Card>}
                </div>

            </div>

        </>;
    }
}

export default withParamsAndNavigate(Shipping);